<template>
  <b-row>
    <b-col cols="4">
      <b-form-group class="form-group">
        <label for="date">Data</label>
        <date-picker
          class="full"
          format="DD/MM/YYYY"
          clearable
          :lang="langDatePicker"
          placeholder="Selecione a data"
          v-model="filters.date"
        />
      </b-form-group>
    </b-col>

    <b-col cols="4"> 
      <b-form-group>
        <label for="item">Procedimento/Item</label>
        <div class="multiselect-container">
          <ItemSelector
            :item="item"
            class="item-container"
            @select="item => selectItem(item)"
            noResultMessage="Nenhum procedimento encontrado"
            v-b-tooltip.html.hover.right="(item) ? item.name : ''"
          />
        </div>
        <Close
          v-if="item"
          class="close-icon top-47"
          @click="selectItem(null)"
        />
      </b-form-group> 
    </b-col>

    <b-col cols="4">
      <b-form-group>
        <label for="profile"> Perfil/ Grau part </label>
        <multiselect
          id="profile"
          v-model="filters.profile"
          :options="profileOptions"
          label="label"
          value="value"
          :option-height="40"
          :showLabels="false"
          :searchable="true"
          placeholder="Selecionar"
          class="with-border"
        >
          <template slot="caret">
            <div class="chevron">
              <ChevronDown v-if="!filters.profile"/>
            </div>
          </template>
          <template slot="noOptions">
            Nenhum perfil encontrado
          </template>
          <template slot="noResult">Nenhum resultado encontrado</template>
        </multiselect>
        <Close
          v-if="filters.profile"
          class="close-icon"
          @click="() => filters.profile = null"
        />
      </b-form-group>
    </b-col>

    <b-col cols="6">
      <b-form-group>
        <label for="patient">Paciente</label>
        <PatientInput
          :showLabel="false"
          v-model="patient"
          @select="selectPatient"
        />
      </b-form-group>
    </b-col>

    <b-col cols="3">
      <b-form-group>
        <label for="healthPlan">Convênio</label>
        <div class="multiselect-container">
          <ClinicHealthPlanSelect
            :selectClinicHealthPlan="selectClinicHealthPlan"
          />
        </div>
      </b-form-group>
    </b-col>

    <b-col cols="3">
      <b-form-group>
        <label for="patient">Regra aplicada</label>
        <multiselect
          id="rule"
          v-model="filters.rule"
          label="label"
          value="value"
          :options="ruleOptions"
          :option-height="40"
          :showLabels="false"
          :searchable="true"
          placeholder="Selecionar"
          class="with-border"
        >
          <template slot="caret"> 
            <div class="chevron">
              <ChevronDown v-if="!filters.rule"/>
            </div>
          </template>
          <template slot="noOptions">
            Nenhuma regra encontrada
          </template>
          <template slot="noResult">Nenhum resultado encontrado</template>
        </multiselect>
        <Close
          v-if="filters.rule"
          class="close-icon diferent-top"
          @click="() => filters.rule = null"
        />
      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
import api from '@/modules/onlending/api.js'
import { getCurrentClinic } from '@/utils/localStorageManager'

export default {
  name: 'Extracts',
  components: {
    ClinicHealthPlanSelect: () => import('@/components/General/ClinicHealthPlanSelect'),
    ChevronDown: () => import('@/assets/icons/chevron-down.svg'),
    Close: () => import('@/assets/icons/close.svg'),
    ItemSelector: () => import('@/components/General/ItemSelector'),
    PatientInput: () => import('@/components/General/PatientInput')
  },
  created(){
    this.getProfiles()
    this.getOnlendingRules()
  },
  data() {
    return {
      clinic: getCurrentClinic(),

      item: null,
      patient: null,
      ruleOptions: [],
      profileOptions: [],

      filters: {
        date: null,
        item_id: null,
        profile: null,
        patient_id: null,
        health_plan_id: null,
        rule: null
      },

      langDatePicker: {
        formatLocale: {
          weekdaysMin: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb']
        }
      }
    }
  },
  methods: {
    getOnlendingRules() {
      const isLoading = this.$loading.show()
      api.getOnlendingRulesForFilterByClinicId(this.clinic.id)
      .then(({data}) => {
        this.ruleOptions = data.map(rule => {
          return {
            label: 'Regra #' +rule.number+ ' - ' + rule.name,
            value: rule.id
          }
        })
      })
      .catch ((error) => {
        this.$toast.error(error.message)
      })
      .finally (() => {
        isLoading.hide()
      })
    },
    getProfiles() {
      const isLoading = this.$loading.show()
      api.getOnlendingProfilesByClinicId(this.clinic.id)
      .then((res) => {
        this.profileOptions = res.data.map(profile => {
          return {
            label: profile.name,
            value: profile.id
          }
        }).sort((a , b) => {
          if(a.label < b.label) return -1;
          else return 1
        })
      })
      .catch ((error) => {
        this.$toast.error(error.message)
      })
      .finally (() => {
        isLoading.hide()
      })
    },

    selectItem(item) {
      this.item = item
      this.filters.item_id = item?.item?.procedure_id ?? item?.item?.id
    },
    selectPatient(patient) {
      this.patient = patient
      this.filters.patient_id = patient?.id ?? null
    },
    selectClinicHealthPlan(clinicHealthPlan){
      this.filters.health_plan_id = clinicHealthPlan?.value ?? null
    },
  },
  watch: {
    filters: {
      handler(newValue) {
        this.$emit('changeFilters', newValue)
      },
      deep: true
    },    
    beneficiary: {
      handler(newValue) {
        this.filters.beneficiary_id = newValue ? newValue.id : null
      },
    }
  }
}
</script>

<style lang="scss" scoped>
.filters-container {
  .search-container {
    display: flex;
    flex-direction: row;
    position: relative;

    .icon-box {
      padding-right: 5px;
      .icon {
        position: absolute;
        width: 24px;
        height: 24px;
        stroke: var(--neutral-500);
        height: 100%;
        margin-left: 15px;
      }
    }

    input {
      padding-left: 40px !important;
    }
  }
}

label{
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: var(--type-active);
  margin-bottom: 4px;
}

.radios{
  display: flex;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  color: var(--type-active);
}

.close-icon {
  width: 25px;
  height: 25px;
  fill: rgb(127, 145, 210);
  position: absolute;
  right: 15px;
  top: 50%;
}

.diferent-top{
  top: 40%;
}

.top-47{
  top: 47%;
}
</style>